interface PrivacySection {
  title: string;
  content: string | string[];
  list?: string[];
  note?: string;
}

interface PrivacyContent {
  title: string;
  effectiveDate: string;
  introduction: string;
  sections: PrivacySection[];
}

const privacyContent: PrivacyContent = {
  title: "Privacy Policy",
  effectiveDate: "November 10, 2024",
  introduction: 'At <i>Topigon</i>, operated by <b>Hest LLC</b> ("Company", "We", "Our", "Us"), your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services ("Services").',
  sections: [
    {
      title: "1. Information We Collect",
      content: "We may collect the following types of information when you use <i>Topigon</i>:",
      list: [
        "<b>Personal Information</b>: When you sign up for an account, we may collect your name, email address, and payment information (for premium subscriptions).",
        "<b>Usage Data</b>: We collect data about how you interact with Topigon, such as pages visited, time spent on the platform, and actions taken within the app (e.g., creating roadmaps).",
        "<b>Device Information</b>: Information about the device you use to access the platform, including browser type, operating system, and IP address."
      ]
    },
    {
      title: "2. How We Use Your Information",
      content: "We use your data to:",
      list: [
        "Provide and improve our services, including personalized features.",
        "Process payments and manage your subscription.",
        "Communicate with you regarding updates, changes, or service-related issues.",
        "Ensure the security and integrity of the platform.",
        "Comply with legal obligations."
      ]
    },
    {
      title: "3. How We Share Your Information",
      content: "We do not sell your personal information. We may share your information with third parties only in the following cases:",
      list: [
        "<b>Service Providers</b>: We may share information with third-party providers that help us operate and maintain Topigon (e.g., payment processors, hosting services).",
        "<b>Legal Compliance</b>: We may disclose your information when required by law or in response to valid legal processes.",
        "<b>Business Transfers</b>: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner."
      ]
    },
    {
      title: "4. Your Rights",
      content: "You have certain rights regarding the information we collect:",
      list: [
        "<b>Access and Update</b>: You can access and update your personal information by logging into your account.",
        "<b>Data Deletion</b>: You may request the deletion of your personal data by contacting us at <a href='mailto:hestapp@gmail.com' class='underline text-blue-600 hover:text-blue-800'>hestapp@gmail.com.</a>",
        "<b>Opt-Out</b>: You can opt-out of non-essential communications (e.g., marketing emails) by following the unsubscribe instructions in those emails."
      ]
    },
    {
      title: "5. Children’s Privacy",
      content: "Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you are under 13, please do not provide any personal information on our platform. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete that information as soon as possible. If you believe that we may have any information from or about a child under 13, please contact us at <a href='mailto:hestapp@gmail.com' class='underline text-blue-600 hover:text-blue-800'>hestapp@gmail.com</a>."
    },
    {
      title: "6. GDPR Compliance (for EU Users)",
      content: "If you are located in the European Union, you have specific rights regarding your personal data under the <b>General Data Protection Regulation (GDPR)</b>. These include the following rights:",
      list: [
        "<b>Right to Access</b>: You can request a copy of the personal data we hold about you.",
        "<b>Right to Rectification</b>: You can ask us to correct or update any inaccurate or incomplete information.",
        "<b>Right to Erasure</b>: You can request that we delete your personal data, subject to certain legal obligations.",
        "<b>Right to Restrict Processing</b>: You can ask us to stop or limit the processing of your data under certain circumstances.",
        "<b>Right to Data Portability</b>: You can request that we provide your personal data in a structured, commonly used, and machine-readable format, or transfer your data to another data controller where technically feasible.",
        "<b>Right to Object</b>: You can object to the processing of your personal data based on our legitimate interests or for direct marketing purposes.",
        "<b>Right to Withdraw Consent</b>: If you have provided consent for us to process your data, you can withdraw that consent at any time.",
      ],
      note: "To exercise any of these rights, please contact us at <a href='mailto:hestapp@gmail.com' class='underline text-blue-600 hover:text-blue-800'>hestapp@gmail.com</a>. We will respond to your request in accordance with applicable GDPR timelines. If you are not satisfied with how we handle your request, you may contact your local data protection authority in the EU."
    },
    {
      title: "7. Cookies and Tracking Technologies",
      content: "We use cookies and similar tracking technologies to improve your experience on Topigon. Cookies help us understand how users interact with the platform and provide relevant content. You can manage cookie preferences through your browser settings."
    },
    {
      title: "8. Data Security",
      content: "We take the security of your data seriously. We use industry-standard encryption and security measures to protect your information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security."
    },
    {
      title: "9. International Users",
      content: "Topigon is available globally, and by using the service, you consent to the transfer of your information to the United States or other countries where our service providers operate."
    },
    {
      title: "10. Changes to This Privacy Policy",
      content: "We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated. Your continued use of the platform constitutes your acceptance of any changes."
    },
    {
      title: "11. Contact Us",
      content: "If you have any questions or concerns about this Privacy Policy, please contact us at <a href='mailto:hestapp@gmail.com' class='underline text-blue-600 hover:text-blue-800'>hestapp@gmail.com</a>."
    }
  ]
};

export default privacyContent;
