import React from 'react'
import termsContent from '../utils/termsAndConditionsData';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="mx-4 md:mx-14 lg:mx-24 px-4 py-8">
      <h1 className="text-3xl font-bold mb-2">{termsContent.title}</h1>
      <p className="text-sm text-gray-600 mb-6">
        Last updated: {termsContent.lastUpdated}
      </p>

      <p className="mb-6">{termsContent.introduction}</p>

      {termsContent.sections.map((section, index) => (
        <section key={index} className="mb-6">
          <h2 className="text-xl font-bold mb-2">{section.title}</h2>
          {Array.isArray(section.content) ? (
            section.content.map((paragraph, i) => (
              <p key={i} className="mb-2 text-xm">
                {paragraph}
              </p>
            ))
          ) : (
            <p className="mb-2">{section.content}</p>
          )}
          {section.list && (
            <ul className="list-disc pl-5 mb-2">
              {section.list.map((item, i) => (
                <li key={i} className="mb-1">
                  {item}
                </li>
              ))}
            </ul>
          )}
          {section.note && (
            <p className="text-sm text-gray-600">{section.note}</p>
          )}
        </section>
      ))}
    </div>
  );
}

export default TermsAndConditions;