import React from "react";

const NotFound: React.FC = () => {
  return (
    <div
      className="flex items-center justify-center min-h-[100vh - 273"
      style={{ minHeight: "calc(100vh - 273px)" }}
    >
      <h1 className="text-4xl font-bold px-4 text-center">
        404 - Page Not Found
      </h1>
    </div>
  );
};

export default NotFound;
