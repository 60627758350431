interface Section {
  title: string;
  content: string | string[];
  list?: string[];
  note?: string;
}

interface TermsContent {
  title: string;
  lastUpdated: string;
  introduction: string;
  sections: Section[];
}

const termsContent: TermsContent = {
  title: "Terms and Conditions",
  lastUpdated: "November 3, 2024",
  introduction: 'Welcome to Topigon, operated by Hest LLC ("Company", "We", "Our", "Us"). By accessing or using our website and services ("Services"), you agree to the following Terms and Conditions ("Terms"). Please read them carefully before using the site or services.',
  sections: [
    {
      title: "1. Jurisdiction",
      content: "These Terms and Conditions are governed by and construed in accordance with the laws of the State of Texas, without regard to its conflict of law principles. Any disputes will be handled under Texas law."
    },
    {
      title: "2. Services",
      content: "Topigon is a project management tool designed to help project managers and engineers create roadmaps and epics, including project metrics. Currently, the paid subscription is set at $15/user/month, but this price may vary in the future. The tool is globally available and includes the following features:",
      list: [
        "Freemium Plan: Limited to creating up to three (3) roadmaps.",
        "Premium Plan: Additional features, including unlimited roadmaps, available with a paid subscription."
      ],
      note: "Please note that epics are not yet available in production."
    },
    {
      title: "3. Payment and Subscription Terms",
      content: "Payment for the premium plan is processed monthly based on your selected subscription. You may cancel or request a refund by managing your account settings or contacting us at hestapp@gmail.com. Refunds will be considered on a case-by-case basis."
    },
    {
      title: "4. User Responsibilities",
      content: [
        "By using Topigon, you agree to:",
        "Use the platform for lawful purposes only.",
        "Refrain from abusing, misusing, or overloading the platform.",
        "Respect the rights and data of other users.",
        "Failure to comply with these rules may result in the suspension or termination of your account."
      ]
    },
    {
      title: "5. Liability and Disclaimers",
      content: [
        'The Services are provided "as is" and "as available," without any warranties of any kind, either express or implied.',
        "Hest LLC will not be liable for any damages, loss of data, or interruption of service, except as required by law.",
        "Any disputes will first be subject to mediation before further legal actions.",
        "To the extent legally permissible, Hest LLC limits its liability in connection with your use of the platform."
      ]
    },
    {
      title: "6. Changes to Terms",
      content: "We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting. Your continued use of the Services constitutes your acceptance of the updated Terms."
    },
    {
      title: "7. Contact Information",
      content: "If you have any questions about these Terms, please contact us at hestapp@gmail.com."
    }
  ]
};

export default termsContent;
