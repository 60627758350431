import React from 'react'
import DOMPurify from 'dompurify';
import privacyContent from '../utils/privacyPolicyData';

const PrivacyPolocy: React.FC = () => {
  return (
    <div className="mx-4 md:mx-14 lg:mx-24 px-4 py-8">
      <h1 className="text-3xl font-bold mb-2">{privacyContent.title}</h1>
      <p className="text-sm text-gray-600 mb-6">
        Effective Date: {privacyContent.effectiveDate}
      </p>

      <p className="mb-6" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyContent.introduction) }}/>

      {privacyContent.sections.map((section, index) => (
      <section key={index} className="mb-6">
        <h2 className="text-xl font-bold mb-2">{section.title}</h2>
        {Array.isArray(section.content) ? (
          section.content.map((paragraph, i) => (
            <p
              key={i}
              className="mb-2 text-sm"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph) }}
            />
          ))
        ) : (
          <p
            className="mb-2"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content) }}
          />
        )}
        {section.list && (
          <ul className="list-disc pl-5 mb-2">
            {section.list.map((item, i) => (
              <li key={i} className="mb-1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }} />
            ))}
          </ul>
        )}
        {section.note && (
          <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.note) }} />
        )}
      </section>
    ))}
    </div>
  );
}

export default PrivacyPolocy;