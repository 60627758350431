import { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from "./Button"

const Header: React.FC = () => {
 const [isMenuOpen, setIsMenuOpen] = useState(false)

 const handleLogin = () => {
  window.location.replace('https://app.topigon.com/login');
 }
 const handleSignUp = () => {
  window.location.replace('https://app.topigon.com/register');
 }
  return (
    <header className="bg-white sticky top-0">
      <div className="mx-4 md:mx-14 lg:mx-24 px-4 sm:px-4 mt-2 ">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/" className="text-blue-500 font-bold text-xl">
              <img src="./logo-icon/topigon-logo-2.png" alt="Topigon" className="h-16" />
              </Link>
            </div>
            <nav className="hidden md:flex space-x-4 ml-8 font-medium text-gray-800">
              <Link to="/use-cases"
                className="text-gray-600 hover:text-gray-900"
              >
                Use Cases
              </Link>
              <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
                Pricing
              </Link>
              <Link to="https://blog.topigon.com" className="text-gray-600 hover:text-gray-900">
                Blog
              </Link>
            </nav>
          </div>
          <div className="hidden md:flex items-center gap-2">
            <button
              className="text-gray-600 pt-[2px] w-[92.41px] hover:text-gray-900"
              onClick={handleLogin}
            >
              Log In
            </button>
            <Button className="pt-[2px]" onClick={handleSignUp}>Sign Up</Button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/use-cases"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Use Cases
            </Link>
            <Link to="/pricing"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Pricing
            </Link>
            <Link to="/blog"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Blog
            </Link>
            <Button
              variant="text"
              className="block w-full px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              onClick={handleLogin}
            >
              Log In
            </Button>
            <Button className="w-full pt-[2px]" onClick={handleSignUp}>Sign Up</Button>
          </div>
        </div>
      )}
      <div className="border-b border-gray-200 mx-[32px] md:mx-[70px] lg:mx-[110px] mt-2"></div>
    </header>
  );
}

export default Header;
