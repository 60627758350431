import React from 'react';

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement | HTMLElement>) => void;
  children: React.ReactNode;
  className: string;
  variant?: Variant;
}
type Variant = "contained" | "outlined" | "text";

const variantStyles: Record<Variant, string> = {
  contained: "bg-blue-600 text-white hover:bg-blue-700 font-medium",
  outlined: "border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white font-medium",
  text: "bg-transparent rounded-full text-blue-600 hover:bg-blue-100 hover:rounded-full hover:text-blue-700 font-medium",
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  variant = "contained",
}) => {
  const baseStyles = "px-4 pb-1 pt-[3px] rounded-full transition duration-300";

return (
  <button
    onClick={onClick}
    className={`${baseStyles} ${variantStyles[variant]} ${className}`}
  >
    {children}
  </button>
);
};

export default Button;
